import { auth } from "../api/Firebase";

export const passwordResetEmail = async (email: string) => {
  await auth
    .sendPasswordResetEmail(email)
    .then(function () {
      alert("Please check your email.");
    })
    .catch(function (error) {
      // An error happened.
      alert("Could not send email.");
    });
};
