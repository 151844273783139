import { FormEvent, useState } from "react";
import { useHistory } from "react-router-dom";
import "../styles/Login.scss";
import { passwordResetEmail } from "../../shared/functions/PasswordResetEmail";
import { hashPassword, isPasswordCorrect } from "../../shared/api/Password";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [email, setEmail] = useState("");

  const onReset = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    await passwordResetEmail(email);
    setLoading(false);
  };

  const login = () => {
    history.push("/login");
  };

  const onHashPassword = async () => {
    const password = window.prompt("Enter password");
    if (!password) return;

    const hash = await hashPassword(password);
    console.log("Hash: ", hash);

    if (!hash) return;

    const originalPassword = await isPasswordCorrect(password, hash);
    console.log("Password: ", originalPassword);
  };

  return (
    <div className="login">
      <div className="left container">
        <div>
          <svg
            width="80"
            height="80"
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
            fill="#fff"
          >
            <g
              className="loading-spin"
              style={{ transformOrigin: "256px 256px" }}
            >
              <path d="M200.043 106.067c-40.631 15.171-73.434 46.382-90.717 85.933H256l-55.957-85.933zM412.797 288A160.723 160.723 0 0 0 416 256c0-36.624-12.314-70.367-33.016-97.334L311 288h101.797zM359.973 134.395C332.007 110.461 295.694 96 256 96c-7.966 0-15.794.591-23.448 1.715L310.852 224l49.121-89.605zM99.204 224A160.65 160.65 0 0 0 96 256c0 36.639 12.324 70.394 33.041 97.366L201 224H99.204zM311.959 405.932c40.631-15.171 73.433-46.382 90.715-85.932H256l55.959 85.932zM152.046 377.621C180.009 401.545 216.314 416 256 416c7.969 0 15.799-.592 23.456-1.716L201.164 288l-49.118 89.621z"></path>
            </g>
          </svg>
          <h1 className="title">Unicoms</h1>
          <div className="moto">Strategic Operations Management System</div>
        </div>
      </div>
      <div className="right container">
        <form className="uk-form-stacked" onSubmit={onReset}>
          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="user-login-email">
              Email
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                id="user-login-email"
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="uk-margin">
            <button className="uk-button uk-margin-right" type="submit">
              Send password reset link
              {loading && (
                <div
                  className="uk-margin-small-left"
                  data-uk-spinner="ratio: 0.5"
                ></div>
              )}
            </button>

            <div
              className="uk-button uk-button-link uk-remove-padding"
              onClick={login}
            >
              Login
            </div>
          </div>
        </form>
      </div>

      <div className="reset-password uk-card uk-card-default uk-card-body">
        <button onClick={onHashPassword}>Hash Pasword</button>
      </div>
    </div>
  );
};

export default ForgotPassword;
