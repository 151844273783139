// password ecryption api-endpoint | https://localhost/projects/unicomms/password_hash.php?
const hashEndpoint = "http://localhost/projects/unicomms/password_hash.php?";
// password verificatoin api-endpoint | https://localhost/projects/unicomms/password_verify.php?
const verifyEndpoint =
  "http://localhost/projects/unicomms/password_verify.php?";

export const hashPassword = async (
  password: string
): Promise<string | null> => {
  // get hash from api
  try {
    const hash = await fetch(`${hashEndpoint}password=${password}`);
    return hash.status === 200 ? hash.text() : null;
  } catch (error) {
    return null;
  }
};

export const isPasswordCorrect = async (
  password: string,
  hash: string
): Promise<string | null> => {
  // verify password from api
  try {
    const verify = await fetch(
      `${verifyEndpoint}hash=${hash}&password=${password}`
    );
    return verify.status === 200 ? verify.text() : null;
  } catch (error) {
    return "error fetching...";
  }
};

export const getPasswordFromHashedPassowrd = async (hashedPassword: string) => {
  const password = window.prompt("Enter password");
  if (!password) return;

  const hash = await hashPassword(password);
  console.log("Hash: ", hash);

  if (!hash) return;

  const originalPassword = await isPasswordCorrect(password, hash);
  console.log("Password: ", originalPassword);
};
