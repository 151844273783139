import { makeAutoObservable, runInAction } from "mobx";
import { Collections } from "../../../../shared/api/Data";
import { db } from "../../../../shared/api/Firebase";
import {
  ICompanyPerformance,
  IDepartmentPerformance,
} from "../../../reports/interfaces/Types";
import DataStore from "./DataStore";

/**
 * Load the company performance
 * Load the company performance Quarter to Quarter
 */

class ReportsStore {
  store: DataStore;

  // Company
  companyPerformance!: ICompanyPerformance;
  isLoadingCompanyPerformance = false;

  companyPerformanceQQ: ICompanyPerformance[] = [];
  isLoadingCompanyPerformanceQQ = false;

  // Departments
  departmentPerformance!: IDepartmentPerformance;
  isLoadingDepartmentPerformance = false;

  departmentPerformanceQQ: IDepartmentPerformance[] = [];
  isLoadingDepartmentPerformanceQQ = false;

  // Sections
  sectionPerformance!: ICompanyPerformance;
  isLoadingSectionPerformance = false;

  sectionPerformanceQQ: ICompanyPerformance[] = [];
  isLoadingSectionPerformanceQQ = false;

  // Top Performers
  topPerformers: any[] = [];
  isLoadingTopPerformers: boolean = false;

  topDeparments: any[] = [];

  topSections: any = null;
  sectionTopPerformers: any[] = [];

  constructor(store: DataStore) {
    this.store = store;
    makeAutoObservable(this, {
      store: false,
    });
    this.loadTopCompanyPerformers();
  }

  // Company
  loadCompanyPerformance(batchId: string = "2021-1") {
    const activeBatch = this.store.batchesStore.activeBatch;
    if (!activeBatch) return;

    this.isLoadingCompanyPerformance = true;
    // Load the document from db.
    const $db = db.collection("performance/companyPerformance/performance");
    $db.doc(batchId).onSnapshot(($doc: any) => {
      const doc = { id: $doc.id, ...$doc.data() };
      runInAction(() => {
        this.companyPerformance = doc;
        this.isLoadingCompanyPerformance = false;
      });
    });
  }

  loadCompanyPerformanceQQ() {
    this.isLoadingCompanyPerformanceQQ = true;
    const $db = db.collection("performance/companyPerformance/performance");
    $db.onSnapshot((querySnapshot: any) => {
      const docs = querySnapshot.docs.map((doc: any) => {
        return { id: doc.id, ...doc.data() };
      });
      runInAction(() => {
        this.companyPerformanceQQ = [...docs];
        this.isLoadingCompanyPerformanceQQ = false;
      });
    });
  }

  // Department
  loadDepartmentPerformance(batchId: string = "2021-1") {
    const activeBatch = this.store.batchesStore.activeBatch;
    if (!activeBatch) return;

    this.isLoadingDepartmentPerformance = true;
    // Load the document from db.
    const $db = db.collection("performance/departments/performance");
    $db.doc(batchId).onSnapshot(($doc: any) => {
      const doc = { id: $doc.id, ...$doc.data() };
      runInAction(() => {
        this.departmentPerformance = doc;
        this.isLoadingDepartmentPerformance = false;
      });
    });
  }
  loadDepartmentPerformanceQQ() {
    this.isLoadingDepartmentPerformanceQQ = true;
    const $db = db.collection("performance/departments/performance");
    $db.onSnapshot((querySnapshot: any) => {
      const docs = querySnapshot.docs.map((doc: any) => {
        return { id: doc.id, ...doc.data() };
      });
      runInAction(() => {
        this.departmentPerformanceQQ = [...docs];
        this.isLoadingDepartmentPerformance = false;
      });
    });
  }
  get getTopDepartments() {
    if (!this.departmentPerformance) return [];
    const performance = this.departmentPerformance.performance;
    const getTopDepartments = performance
      .slice()
      .sort((a, b) => b.overallAvgRating - a.overallAvgRating);
    return getTopDepartments;
  }

  // Section
  loadSectionPerformance(batchId: string = "2021-1") {
    const activeBatch = this.store.batchesStore.activeBatch;
    if (!activeBatch) return;
    this.isLoadingSectionPerformance = true;
    // Load the document from db.
    const $db = db.collection("performance/sections/performance");
    $db.doc(activeBatch.asJson.id).onSnapshot(($doc: any) => {
      const doc = { id: $doc.id, ...$doc.data() };
      runInAction(() => {
        this.sectionPerformance = doc;
        this.isLoadingSectionPerformance = false;
      });
    });
  }
  loadSectionPerformanceQQ(sectionId: string) {}

  // Top Performers
  loadTopPerformers(
    scope: "company" | "department" = "company",
    limit = 5,
    departmentId?: string
  ) {
    if (scope === "company") this.loadTopCompanyPerformers(limit);
    else if (scope === "department" && departmentId)
      this.loadTopDepartmentalPerformers(departmentId, limit);
  }
  private loadTopCompanyPerformers(limit: number = 5) {
    const activeBatch = this.store.batchesStore.activeBatch;
    if (!activeBatch) return;
    this.isLoadingTopPerformers = true;
    const $db = db.collection(Collections.SCORECARDS_COLLECTION);
    $db
      .where("scorecardBatchId", "==", activeBatch!.asJson.id)
      .orderBy("avgRating", "desc")
      .limit(limit)
      .onSnapshot((querySnapshot: any) => {
        const docs = querySnapshot.docs.map((doc: any) => {
          return { id: doc.id, ...doc.data() };
        });
        runInAction(() => {
          this.topPerformers = [...docs];
          this.isLoadingTopPerformers = false;
        });
      });
  }
  private loadTopDepartmentalPerformers(
    departmentId: string,
    limit: number = 5
  ) {
    const activeBatch = this.store.batchesStore.activeBatch;
    if (!activeBatch) return;

    this.isLoadingTopPerformers = true;
    const $db = db.collection(Collections.SCORECARDS_COLLECTION);
    $db
      .where("departmentId", "==", departmentId)
      .where("scorecardBatchId", "==", activeBatch!.asJson.id)
      .orderBy("avgRating", "desc")
      .limit(limit)
      .onSnapshot((querySnapshot: any) => {
        const docs = querySnapshot.docs.map((doc: any) => {
          return { id: doc.id, ...doc.data() };
        });
        runInAction(() => {
          this.topPerformers.length = 0;
          this.topPerformers = [...docs];
          this.isLoadingTopPerformers = false;
        });
      });
  }
}

export default ReportsStore;
