import { makeAutoObservable, runInAction } from "mobx";
import { Collections } from "../../../../shared/api/Data";
import { db } from "../../../../shared/api/Firebase";
import DataStore from "./DataStore";

/**
 * Load departments
 * Update departments
 * Remove departments
 * Create departments
 */

interface IDepartment {
  id: string;
  name: string;
  noOfUsers: number;
}
class DepartmentsStore {
  selectedDepartment: Department | null = null;
  departments: Department[] = [];
  isLoading = false;
  store: DataStore;

  constructor(store: DataStore) {
    makeAutoObservable(this, {
      store: false,
    });
    this.store = store;
  }

  loadDepartments() {
    this.isLoading = true;
    const $db = db.collection(Collections.DEPARTMENTS_COLLECTION);
    $db.onSnapshot((querySnapshot: any) => {
      const docs = querySnapshot.docs.map((doc: any) => {
        return { id: doc.id, ...doc.data() };
      });
      runInAction(() => {
        docs.forEach((doc: IDepartment) =>
          this.updateDepartmentFromServer(doc)
        );
        this.isLoading = false; // on load, set isLoadingDepartments=false
      });
    });
  }

  createDepartmentFromObj(data: Department) {
    const $doc = db.collection(Collections.DEPARTMENTS_COLLECTION).doc();
    const newData = data.department;
    newData.id = $doc.id;
    $doc.set(newData, { merge: true });
  }

  updateDepartmentFromServer(departmentJson: IDepartment) {
    let department = this.departments.find(
      (m) => m.asJson.id === departmentJson.id
    );
    if (!department) {
      department = new Department(this, departmentJson);
      this.departments.push(department);
    } else {
      department.updateFromJson(departmentJson);
    }
  }

  createDepartment(data: IDepartment) {
    const department = new Department(this, data);
    db.collection(Collections.DEPARTMENTS_COLLECTION)
      .doc()
      .set(data, { merge: true });
    return department;
  }

  updateDepartment(department: Department) {
    const data = department.asJson;
    db.collection(Collections.DEPARTMENTS_COLLECTION)
      .doc(data.id)
      .set(data, { merge: true }); // Update Department in firebase
  }

  delete(department: Department) {
    const id = department.asJson.id;
    db.collection(Collections.DEPARTMENTS_COLLECTION).doc(id).delete(); // Delete from firebase
    this.departments.splice(this.departments.indexOf(department), 1); // Remove from memory
  }

  selectDepartment(department: Department) {
    this.selectedDepartment = department;
  }

  clearSelectedDepartment() {
    this.selectedDepartment = null;
  }

  get getDepartments() {
    return this.departments;
  }
}

/**
 * Create departments tasks
 * Get departments tasks
 * Update departments tasks
 * Delete departments tasks
 */
export class Department {
  department: IDepartment;
  store: DepartmentsStore;

  constructor(store: DepartmentsStore, department: IDepartment) {
    makeAutoObservable(this, {
      store: false,
    });
    this.store = store;
    this.department = department;
  }

  save() {
    this.store.updateDepartment(this);
  }

  updateFromJson(department: IDepartment) {
    this.department = department;
  }

  create() {
    this.store.createDepartmentFromObj(this);
  }

  delete() {
    this.store.delete(this);
  }

  select() {
    this.store.selectDepartment(this);
  }

  get asJson() {
    return this.department;
  }
}

type IDepartmentsStore = DepartmentsStore;
type IDepartmentObject = Department;

export type { IDepartment, IDepartmentObject, IDepartmentsStore };

export default DepartmentsStore;
