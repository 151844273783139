import React from "react";
import "../styles/Loader.scss";

const Loading = () => {
  return (
    <div className="full-width-loader">
      <div className="lds-dual-ring"></div>
    </div>
  );
};

export default Loading;
