import { makeAutoObservable, runInAction } from "mobx";
import { Collections } from "../../../../shared/api/Data";
import { db } from "../../../../shared/api/Firebase";
import { IScorecardObject } from "./ScorecardStore";

type AssignmentStatus = "Resolved" | "In-Progress" | "Archived";
type AssignmentPriorities = "High" | "Medium" | "Low";
interface IAssignment {
  id: string;
  scorecardBatchId: string;
  senderId: string;
  participants: string[];
  users: string[];
  status: AssignmentStatus;
  subject: string;
  description: string;
  priority: AssignmentPriorities;
  createdAt: string;
}
class AssignmentsStore {
  assignments: Assignment[] = [];
  isLoading = false;
  scorecard: IScorecardObject;

  constructor(store: IScorecardObject) {
    makeAutoObservable(this);
    this.scorecard = store;
    this.loadAssignments();
  }

  loadAssignments() {
    this.isLoading = true;
    if (!this.scorecard || typeof this.scorecard == "undefined") {
      this.isLoading = false;
      return;
    }

    const $db = db
      .collection(Collections.ASSIGNMENT_COLLECTION)
      .where("scorecardBatchId", "==", this.scorecard!.asJson.scorecardBatchId)
      .where("users", "array-contains", this.scorecard!.asJson.uid);
    $db.onSnapshot((querySnapshot: any) => {
      const docs = querySnapshot.docs.map((doc: any) => {
        return { id: doc.id, ...doc.data() };
      });
      runInAction(() => {
        docs.forEach((doc: any) => this.updateAssignmentFromServer(doc));
        this.isLoading = false; // on load, set isLoadingMeasures=false
      });
    });
  }

  createAssignmentFromObj(data: Assignment) {
    const newData = data.assignment;
    if (this.scorecard) {
      const $doc = db.collection(Collections.ASSIGNMENT_COLLECTION).doc();
      newData.senderId = this.scorecard.asJson.uid;
      newData.id = $doc.id;

      $doc.set(newData, { merge: true });
    } else {
      alert("Cannot create an Assignmetn without , Go to My Scorecard!");
    }
  }

  updateAssignmentFromServer(assignmentJson: any) {
    let assignment = this.assignments.find(
      (m: any) => m.asJson.id === assignmentJson.id
    );
    if (!assignment) {
      assignment = new Assignment(this, assignmentJson);
      this.assignments.push(assignment);
    } else {
      assignment.updateFromJson(assignmentJson);
    }
  }

  createAssignment(data: IAssignment) {
    const assignment = new Assignment(this, data);
    db.collection(Collections.ASSIGNMENT_COLLECTION)
      .doc()
      .set(data, { merge: true });
    return assignment;
  }

  updateAssignment(assignment: Assignment) {
    const data = assignment.asJson;
    db.collection(Collections.ASSIGNMENT_COLLECTION)
      .doc(data.id)
      .set(data, { merge: true }); // Update Assignment in firebase
  }

  resolveAssignment(assignment: Assignment) {
    const resolved = assignment;
    resolved.assignment.status = "Resolved";
    this.updateAssignment(resolved);
  }
  archiveAssignment(assignment: Assignment) {
    const resolved = assignment;
    resolved.assignment.status = "Archived";
    this.updateAssignment(resolved);
  }

  delete(assignment: Assignment) {
    const id = assignment.asJson.id;
    db.collection(Collections.ASSIGNMENT_COLLECTION).doc(id).delete(); // Delete from firebase
    this.assignments.splice(this.assignments.indexOf(assignment), 1); // Remove from memory
  }

  selectAssignment(assignment: Assignment) {
    this.scorecard.scorecardStore.selectAssignment(assignment);
  }

  clearSelectedAssignment() {
    this.scorecard.scorecardStore.clearSelectedAssignment();
  }

  get getAssignments() {
    return this.assignments;
  }
  get getTotalNoOfAssignments() {
    return this.getAssignments.filter(
      (assignments) => assignments.asJson.status !== "Archived"
    ).length;
  }
  get getNoOfAssignmentsInProgress() {
    return this.getAssignments.filter(
      (assignments) => assignments.asJson.status === "In-Progress"
    ).length;
  }
  get getNoOfAssignmentsResolved() {
    return this.getAssignments.filter(
      (assignments) => assignments.asJson.status === "Resolved"
    ).length;
  }
  get getNoOfAssignmentsArchived() {
    return this.getAssignments.filter(
      (assignments) => assignments.asJson.status === "Archived"
    ).length;
  }
}

/**
 *
 */
export class Assignment {
  assignment: IAssignment;
  store: AssignmentsStore;

  constructor(store: AssignmentsStore, assignment: IAssignment) {
    makeAutoObservable(this, {
      store: false,
    });
    this.store = store;
    this.assignment = assignment;
  }

  save() {
    this.store.updateAssignment(this);
  }

  resolve() {
    this.store.resolveAssignment(this);
  }

  archive() {
    this.store.archiveAssignment(this);
  }

  updateFromJson(assignment: any) {
    this.assignment = assignment;
  }

  create() {
    this.store.createAssignmentFromObj(this);
  }

  delete() {
    // First Delete from firebase
    this.store.delete(this); // Then Remove from memory
  }

  select() {
    this.store.selectAssignment(this);
  }

  get asJson() {
    return this.assignment;
  }
}

type IAssignmentsStore = AssignmentsStore;
type IAssignmentObject = Assignment;

export type {
  IAssignment,
  IAssignmentObject,
  AssignmentStatus,
  AssignmentPriorities,
  IAssignmentsStore,
};

export default AssignmentsStore;
