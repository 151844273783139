import { makeObservable, runInAction } from "mobx";
import IBlobItem, { BlobItemModel } from "../../interfaces/IBlobItem";
import DataStore from "./DataStore";
import { Collections } from "../../../../shared/api/Data";
import { db, storage } from "../../../../shared/api/Firebase";

export default class FileViewerStore {
  selected: BlobItemModel | null = null;
  protected store: DataStore;

  constructor(store: DataStore) {
    this.store = store;

    makeObservable(this, {
      selected: true,
    });
  }

  select(item: IBlobItem) {
    runInAction(() => {
      this.selected = new BlobItemModel(item);
    });
  }

  onOpen(url: string) {
    runInAction(() => {
      window.open(
        url,
        "_blank" // <- This is what makes it open in a new window.
      );
    });
  }

  clearSelected() {
    runInAction(() => {
      this.selected = null;
    });
  }
  delete(downloadURL: string) {
    if (downloadURL) return storage.refFromURL(downloadURL).delete();
  }
}
