import $firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const secondaryAppConfig = {
  apiKey: "AIzaSyAOGuEkFmygUyLFydmvRh96NANJAM7t-5Y",
  authDomain: "transnamib-backup.firebaseapp.com",
  projectId: "transnamib-backup",
  storageBucket: "transnamib-backup.appspot.com",
  messagingSenderId: "835560127317",
  appId: "1:835560127317:web:e9e3fff64c51d52d888ddc",
  measurementId: "G-JQG3VRYG4S",
};

// Initialize Firebase
$firebase.initializeApp(firebaseConfig);
const secondaryApp = $firebase.initializeApp(secondaryAppConfig, "secondary");

$firebase.analytics();

// $firebase
//   .firestore()
//   .enablePersistence()
//   .catch((err) => {
//     if (err.code === "failed-precondition") {
//       // Multiple tabs open, persistence can only be enabled
//       // in one tab at a a time.
//       // ...
//     } else if (err.code === "unimplemented") {
//       // The current browser does not support all of the
//       // features required to enable persistence
//       // ...
//     }
//   });

let authWorkerApp = $firebase.initializeApp(
  $firebase.app().options,
  "auth-worker"
);
let authWorkerAuth = $firebase.auth(authWorkerApp);
authWorkerAuth.setPersistence($firebase.auth.Auth.Persistence.NONE); // disables caching of account credentials

// authWorkerAuth.createUserWithEmailAndPassword(email, password).catch(function(error) {
//     // Handle Errors here.
//     var errorCode = error.code;
//     var errorMessage = error.message;
//     // ...
// });

export const firebase = $firebase;
export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const functions = firebase.functions();

export const authWorker = authWorkerApp.auth();

export const database = {
  folders: db.collection("folders"),
  files: db.collection("files"),
  formatDoc: (doc: any) => {
    return { id: doc.id, ...doc.data() };
  },
  getCurrentTimestamp: firebase.firestore.FieldValue.serverTimestamp,
};

export const secondaryFire = {
  firebase: secondaryApp,
  db: secondaryApp.firestore(),
  storage: secondaryApp.storage(),
  functions: secondaryApp.functions(),
  auth: secondaryApp.auth(),
};
