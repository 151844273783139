import { makeAutoObservable } from "mobx";

export const defaultBlobItem: IBlobItem = {
  name: "",
  url: "",
  id: "",
  uid: "",
  folderId: "",
  createdAt: 0,
};

// <Blobs list >
export default interface IBlobItem {
  name: string;
  url: string;
  id: string;
  uid: string;
  folderId: string;
  createdAt: number;
}

export class BlobItemModel implements IBlobItem {
  id: string;
  uid: string;
  name: string;
  url: string;
  folderId: string;
  createdAt: number;

  constructor(blob: IBlobItem) {
    makeAutoObservable(this);
    this.id = blob.id;
    this.uid = blob.uid;
    this.name = blob.name;
    this.url = blob.url;
    this.url = blob.url;
    this.folderId = blob.folderId;
    this.createdAt = blob.createdAt;
  }

  get asJson() {
    const blob: IBlobItem = {
      name: this.name,
      url: this.url,
      id: this.id,
      uid: this.uid,
      createdAt: this.createdAt,
      folderId: this.folderId,
    };
    return blob;
  }
}
