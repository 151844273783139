import { db, storage } from "./Firebase";

export const Collections = {
  OBJECTIVES_COLLECTION: "objectives",
  ASSIGNMENT_COLLECTION: "assignments",
  PROJECTS_COLLECTION: "projects",
  SCORECARDS_COLLECTION: "scorecards",
  TASKS_COLLECTION: "tasks",
  USERS_COLLECTION: "users",
  DEPARTMENTS_COLLECTION: "departments",
  BATCHES_COLLECTION: "scorecardBatches",
  MEASURES_COLLECTION: "measures",
  HISTORY_COLLECTION: "history",
};

export const readDoc = async (collection: string, doc: string, setter: any) => {
  const $db = await db.collection(collection).doc(doc);
  return $db.onSnapshot((doc) => {
    setter({ id: doc.id, ...doc.data() });
  });
};

export const readData = (collection: string, setter: any) => {
  const $db = db.collection(collection);
  return $db.onSnapshot((querySnapshot: any) => {
    const docs = querySnapshot.docs.map((doc: any) => {
      return { id: doc.id, ...doc.data() };
    });
    setter(docs);
  });
};

export const addDoc = (collection: string, doc: string, data: any) => {
  const $db = db.collection(collection).doc(doc);
  return $db.set(data, { merge: true });
};

export const addDocFromRef = (ref: any, data: any) => {
  const $ref = ref;
  return $ref.set(data, { merge: true });
};

export const addDocWithImage = (
  docRef: any,
  storagePath: string,
  data: any,
  file: File
) => {
  const ref = storage.ref().child(storagePath);
  const uploadTask = ref.put(file);
  return uploadTask.then(async (snapshot) => {
    const url = await snapshot.ref.getDownloadURL();
    const $data = { ...data, images: [url] };
    return addDocFromRef(docRef, $data);
  });
};

export const deleteDoc = (collection: string, doc: string) => {
  return db.collection(collection).doc(doc).delete();
};

export const deleteFromStorageWithDownloadURL = (downloadURL: string) => {
  // Delete from storage (using DownloadURL).
  if (downloadURL) return storage.refFromURL(downloadURL).delete();
  // Else if done uploading,
};
// delete folderFile
// export const deleteFile = (downloadURL: string) => {
//   if (downloadURL) return storage.refFromURL(downloadURL).delete();
// };
